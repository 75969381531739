// app/javascript/controllers/driver_admin_visibility_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["adminContent"]
  static values = { isAdmin: Boolean }

  connect() {
    this.updateAdminContent()
    this.setupMutationObserver()
  }

  disconnect() {
    this.observer.disconnect()
  }

  isAdminValueChanged() {
    this.updateAdminContent()
  }

  updateAdminContent() {
    if (!this.isAdminValue) {
      this.adminContentTargets.forEach(element => {
        element.remove()
      })
    }
  }

  setupMutationObserver() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          const addedNodes = Array.from(mutation.addedNodes)
          addedNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              const adminContents = node.querySelectorAll('[data-driver-admin-visibility-target="adminContent"]')
              adminContents.forEach(content => {
                if (!this.isAdminValue) {
                  content.remove()
                }
              })
            }
          })
        }
      })
    })

    this.observer.observe(this.element, {
      childList: true,
      subtree: true
    })
  }
}
