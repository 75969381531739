import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    duration: { type: Number, default: 2000 },
    fadeOutDuration: { type: Number, default: 2000 }
  }

  connect() {
    this.element.style.transition = `opacity ${this.fadeOutDurationValue}ms`
    this.scheduleRemoval()
  }

  scheduleRemoval() {
    this.timeout = setTimeout(() => {
      this.startFadeOut()
    }, this.durationValue)
  }

  startFadeOut() {
    this.element.style.opacity = '0'
    
    this.fadeOutTimeout = setTimeout(() => {
      this.removeSelf()
    }, this.fadeOutDurationValue)
  }

  removeSelf() {
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element)
    } else {
      console.log("Element already removed or not in DOM")
    }
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    if (this.fadeOutTimeout) {
      clearTimeout(this.fadeOutTimeout)
    }
  }
}

