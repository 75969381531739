import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

class Upload {
  static completedUploads = 0;
  static totalUploads = 0;

  constructor(file, onCompleteCallback, formElement) {
    this.directUpload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this);
    this.onCompleteCallback = onCompleteCallback;
    this.formElement = formElement;
    this.progressDisplayElement = document.querySelector("#uploads-to-display");
    this.uploadDisplayElement = null;
  }

  process() {
    this.insertUploadDisplay();
    this.startUpload();
  }

  async createArchive(error, blob) {
    if (error) {
      console.error("Erro no upload:", error);
    } else {
      const archiveData = { archive: { filename: blob.filename }, signed_blob_id: blob.signed_id };
      // Aqui você pode fazer algo com archiveData se necessário
    }
  }

  insertUploadDisplay() {
    this.uploadDisplayElement = document.createElement("div");
    this.uploadDisplayElement.id = `upload_display_${this.directUpload.id}`;
    this.uploadDisplayElement.className = "upload-item our-archive-upload-item";
    const fileName = document.createElement("span");
    fileName.textContent = this.directUpload.file.name;
    this.uploadDisplayElement.appendChild(fileName);
    const progressWrapper = document.createElement("div");
    progressWrapper.className = "progress";
    progressWrapper.style = "height: 4px; margin-top: 3px;";
    this.progressBar = document.createElement("div");
    this.progressBar.className = "progress-bar bg-primary";
    this.progressBar.style = "width: 0%";
    progressWrapper.appendChild(this.progressBar);
    this.uploadDisplayElement.appendChild(progressWrapper);
    this.progressDisplayElement.appendChild(this.uploadDisplayElement);
  }

  startUpload() {
    this.directUpload.create((error, blob) => {
      this.createArchive(error, blob);
      this.addHiddenInputsToForm(blob);
      Upload.completedUploads++;
      if (Upload.completedUploads === Upload.totalUploads) {
        this.onCompleteCallback();
      }
    });
  }

  addHiddenInputsToForm(blob) {
    const inputFieldName = document.createElement("input");
    inputFieldName.type = "hidden";
    inputFieldName.name = `archives[${this.directUpload.id}][name]`;
    inputFieldName.value = blob.filename;
    this.formElement.appendChild(inputFieldName);
    const inputFieldId = document.createElement("input");
    inputFieldId.type = "hidden";
    inputFieldId.name = `archives[${this.directUpload.id}][id]`;
    inputFieldId.value = blob.id;
    this.formElement.appendChild(inputFieldId);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.updateProgress(event));
  }

  updateProgress(event) {
    const percentage = (event.loaded / event.total) * 100;
    this.progressBar.style.width = `${percentage}%`;
    if (percentage === 100) {
      this.progressBar.className = "progress-bar bg-success";
      this.fadeOutAndRemove();
    }
  }

  fadeOutAndRemove() {
    this.uploadDisplayElement.style.transition = "opacity 0.9s ease-out";
    this.uploadDisplayElement.style.opacity = "0";
    setTimeout(() => {
      this.uploadDisplayElement.remove();
    }, 900);
  }
}

export default class extends Controller {
  static targets = ["fileInput", "submitButton"];
  static values = {
    action: String
  }

  connect() {
    this.progressDisplayElement = document.querySelector("#uploads-to-display");
    this.blankStateElement = document.querySelector("#blank-state");
  }

  acceptFiles(event) {
    event.preventDefault();
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    Upload.completedUploads = 0;
    Upload.totalUploads = files.length;
    if (this.blankStateElement) {
      this.blankStateElement.remove();
    }
    const onCompleteCallback = () => {
      this.submitButtonTarget.click();
      this.fileInputTarget.value = null;
    };
    [...files].forEach((file) => {
      new Upload(file, onCompleteCallback, this.element).process();
    });
  }
}

