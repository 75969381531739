import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    duration: { type: Number, default: 3500 } // duração em milissegundos antes de remover o highlight
  }

  connect() {
    if (this.element.classList.contains('highlight')) {
      this.scheduleRemoval()
    } else {
      this.disconnect()
    }
  }

  scheduleRemoval() {
    this.timeout = setTimeout(() => {
      this.removeHighlight()
    }, this.durationValue)
  }

  removeHighlight() {
    this.element.classList.remove('highlight')
    this.disconnect()
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }
}

