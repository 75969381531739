import { Application } from "@hotwired/stimulus"
import CheckboxSelectAll from "@stimulus-components/checkbox-select-all"
import TextareaAutogrow from "stimulus-textarea-autogrow"
import Popover from "@stimulus-components/popover"

const application = Application.start()
application.register("checkbox-select-all", CheckboxSelectAll)
application.register("textarea-autogrow", TextareaAutogrow)
application.register("popover", Popover)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
